.container {
  height: fit-content;
  padding: 5px;
}
.cartCard {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 8px;
  border-radius: 5px;
}
.productImg {
  height: 80px;
  width: 80px;
  border-radius: 5px;
  vertical-align: middle;
}
.cartItemNameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.priceWrapper {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.priceOld {
  color: #e02c2b;
  margin-right: 12px;
  font-size: 16px;
  text-decoration: line-through;
}
.qtyWrapper {
  text-align: end;
}
.qtyInput {
  width: 50px;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
  border: 0.1px solid #ccc;
  padding: 4px;
  text-align: center;
}
.subTotal {
  font-weight: bold;
  margin-top: 10px;
  font-size: 15px;
}
