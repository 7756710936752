body > iframe[style*='2147483647'] {
    display: none;
}

h3, h5 {
    margin: 0;
}

.ContentsWrapper {
    position: absolute;
    top: 65px;
    height: calc(100% - 125px);
    width: 100vw;
    display: flex;
}

.ContentsArea {
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.CartSummaryLayout {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999999999;
    background: #e4e4e4;
}
