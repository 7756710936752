.productImgParent {
    height: 150px;
    max-width: 150px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
}
.productImage {
    width: 90%;
    height: 90%;
}
.productImage:hover {
    transform: scale(1.05);
}
.flexParent {
    margin-top: 4px;
}
.flexLeft {
    justify-content: flex-start;
    color: #2f333a;
    font-size: 14px;
    font-weight: 500;
}
.footer {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}
.priceOld {
    color: #e02c2b;
    margin-left: 12px;
    font-size: 16px;
    text-align: center;
    text-decoration: line-through;
}
.flexChildRight {
    font-size: 15px;
    display: flex;
    width: 100%;
}

.cartQuantityText {
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.productCard {
    height: 310px;
    overflow: hidden;
}
.productCardTop {
    padding: 10px;
    height: calc(100% - 58px);
}
.cartButton {
    height: 38px;
    width: 100%;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.buttonAddToCart {
    background:none;
    width: 100%;
    height: 100%;
    border: none;
}
.addToCartBtnText {
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}
