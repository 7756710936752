.cartCard {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 8px;
  padding: 8px;
  border-radius: 5px;
}
.cartLeft {
  margin-right: auto;
  flex: 20%;
  padding: 10px;
}
.cartMiddle {
  flex: 50%;
  padding: 10px;
  color: black;
}
.cartRight {
  flex: 20%;
  padding: 10px;
}
.productImg {
  height: 80px;
  width: 80px;
  border-radius: 5px;
  vertical-align: middle;
}
.priceWrapper {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.priceOld {
  color: #e02c2b;
  margin-right: 12px;
  font-size: 16px;
  text-decoration: line-through;
}

.qtyInput {
  width: 50px;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
  border: 0.1px solid #ccc;
  padding: 4px;
  text-align: center;
}
.deleteIcon {
  float: right;
}
.CartTotalDiv {
  font-size: 20px;
  color: black;
  display: flex;
}
.popupContentDiv {
  padding: 0 !important;
  border-top: 1px solid #ccc8;
  border-bottom: 1px solid #ccc8;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.subTitleSerchBar {
  color: #1ebf5a;
  font-size: 11px;
  margin-top: 3px;
}
.searchDiv {
  height: 50% !important;
  width: 55% !important;
}
.marginContent {
  margin-top: 6px;
}
.cartButton {
  height: 38px;
  border-radius: 6px;
  background-color: #1ebf5a;
  width: 100%;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tickArrow {
  font-size: 150px !important;
  color: #1ebf5a;
}
.statusDialogBox {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center !important;
}
.cartItemNameWrapper {
  min-height: 55px;
  max-height: 65px;
}
.subTotal {
  font-weight: bold;
  margin-top: 10px;
  font-size: 15px;
}
.qtyWrapper {
  text-align: end;
}

@media (max-width: 600px) {
  .CartTotalDiv {
    flex-direction: column;
  }
}
