.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.banner {
  width: 95%;
  height: 95%;
}
.bannerMain {
  height: 100%;
  flex-direction: column;
}
.flexRow {
  display: flex;
  height: calc(100% - 40px);
  flex-direction: row;
}
.bannerDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bannerImgleft {
  flex: 50%;
}
.bannerImgRight {
  flex: 50%;
  height: 50%;
}
.bannerImg {
  width: auto;
  height: auto;
}
.bannerImgPhone {
  width: 300px;
  height: 650px;
}
.bannerTitle {
  width: 100%;
  height: 100%;
}
.welcome {
  color: red;
}
.whatsappIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 50px;
  margin-right: 10px;
}
