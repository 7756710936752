
.subtotalArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  padding: 5px;
  font-weight: 400;
  line-height: 1.5;
}
.layoutPlaceOrder{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.placeOrderBtn {
  text-align: center;
  place-items: center;
  color: white;
  font-weight: 600;
}

.count {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media (min-width: 900px) {
  .ContentArea {
    width: 50% !important;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  }
  .subtotalArea {
    padding: 8px;
  }
}
