.ContentWrapper {
  min-height: calc(100vh - 65px);
  padding: 10px;
  background: #f4f4f4;
}
.contentsPaper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 8px;
}
.detailsContainer {
  padding: 15px;
}
.infoContainer {
  padding-bottom: 6px;
}
.qrContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.backToMenu {
  display: flex;
  align-items: center;
  padding: 6px;
  color: #747474;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
}
